import { useState, useEffect } from "react";

import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ContactsIcon from "@mui/icons-material/Contacts";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export const profile = [
  {
    nameKey: "my_profile",
    component: (
      <PermIdentityOutlinedIcon style={{ color: "rgba(0, 142, 122, 1)" }} />
    ),
    path: "/vendor/myProfile",
  },
  {
    nameKey: "contact_details",
    component: <ContactsIcon style={{ color: "rgba(0, 142, 122, 1)" }} />,
    path: "/vendor/contact-details",
  },
  {
    nameKey: "bank_details",
    component: <AccountBalanceIcon style={{ color: "rgba(0, 142, 122, 1)" }} />,
    path: "/vendor/bank-details",
  },
  {
    nameKey: "customer_cares",
    component: <SupportAgentIcon style={{ color: "rgba(0, 142, 122, 1)" }} />,
    path: "/vendor/customer-care",
  },
  {
    nameKey: "Notification",
    component: (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0958 16.0055C15.1143 15.7665 17.0975 15.2902 19.0043 14.5863C17.3895 12.7975 16.4972 10.4724 16.5008 8.0625V7.30417V7.25C16.5008 5.52609 15.8159 3.87279 14.597 2.65381C13.378 1.43482 11.7247 0.75 10.0008 0.75C8.27685 0.75 6.62355 1.43482 5.40457 2.65381C4.18558 3.87279 3.50076 5.52609 3.50076 7.25V8.0625C3.50403 10.4725 2.61133 12.7977 0.996094 14.5863C2.87351 15.2797 4.85276 15.7617 6.90568 16.0055M13.0958 16.0055C11.0397 16.2494 8.96186 16.2494 6.90568 16.0055M13.0958 16.0055C13.252 16.4928 13.2908 17.0102 13.2091 17.5154C13.1275 18.0206 12.9277 18.4993 12.6261 18.9127C12.3244 19.3261 11.9294 19.6624 11.4732 19.8942C11.017 20.1261 10.5125 20.2469 10.0008 20.2469C9.48902 20.2469 8.98451 20.1261 8.5283 19.8942C8.0721 19.6624 7.67709 19.3261 7.37543 18.9127C7.07378 18.4993 6.87401 18.0206 6.79238 17.5154C6.71074 17.0102 6.74957 16.4928 6.90568 16.0055"
          stroke="#008E7A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    path: "/notification",
  },
  {
    nameKey: "AboutEvent",
    component: (
      <ErrorOutlineOutlinedIcon style={{ color: "rgba(0, 142, 122, 1)" }} />
    ),
    path: "/about",
  },
];
