import { ADDON_SECTION_SUCCESS, ADDON_SECTION_FAILURE, FETCH_ADDON_SECTION_BY_ID_SUCCESS, FETCH_ADDON_SECTION_BY_ID_FAILURE, DELETE_ADDON_SECTION_SUCCESS, DELETE_ADDON_SECTION_FAILURE } from '../actions/actiontypes';

const initialState = {
    addonSection: null,
    error: null
};

const addonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADDON_SECTION_SUCCESS:
            return {
                ...state,
                addonSection: action.payload,
                error: null
            };
        case ADDON_SECTION_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case FETCH_ADDON_SECTION_BY_ID_SUCCESS:
            return {
                ...state,
                addonSection: action.payload,
                error: null
            };
        case FETCH_ADDON_SECTION_BY_ID_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case DELETE_ADDON_SECTION_SUCCESS:
            // Filter out the deleted addon section from the state
            return {
                ...state,
                addonSection: state.addonSection.data.filter(addonSection => addonSection._id !== action.payload),
                error: null
            };
        case DELETE_ADDON_SECTION_FAILURE:
            return {
                ...state,
                error: action.error
            };

        default:
            return state;
    }
};

export default addonReducer;
