import { SET_DATE } from "../actions/actiontypes";

const initialState = {
    selectedDate: null, // Initialize with a default date if needed
  };
  
  export const dateReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_DATE:
        return {
          ...state,
          selectedDate: action.payload,
        };
      default:
        return state;
    }
  };