import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import logo from "../../assert/image/logoNewImage.png";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Box from "@mui/material/Box";
import { Typography } from "@material-ui/core";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
// import { GoogleMap , useLoadScript , Marker } from "@react-google-maps/api";
// import { config } from "../config";
import "../../assert/css/navbar.css";
import dayjs from "dayjs";
import useUserContext, { userLanguage } from "../../contexts/userContext";
import home from "../../transalation/home.json";
import { useState } from "react";
import { useEffect } from "react";
import ProfilePopUp from "./MyProfile";
import { useNavigate } from "react-router-dom";
import VendorProfilePopUP from "../vendor/MyProfile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dummyProfileImg from "./assets/images/images.jpeg";
import evente from "../../assert/image/logo.png";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { format } from "date-fns";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import LocationModal from "./LocationModal";
import { colors } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../../redux/actions/dateActions";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  sideNav: {},

  mobileLinkList: {
    "& li": {
      padding: "0px !important",
    },
    "& li  + li": {
      borderTop: "1px solid rgb(0 110 95) !important",
    },
    "& li .MuiListItemButton-root": {
      padding: "8px 10px !important",
      justifyContent: "start !important",
    },
    "& a": {
      textDecoration: "none",
      color: "#fff",
      fontFamily: "Inter",
      fontSize: "16px",
    },
  },
  userCustomHeader: {
    "@media screen and (max-width:900px)": {
      width: "55%",
    },
    "@media screen and (max-width:480px)": {
      width: "60%",
    },
  },
  bgBlack: {
    background: "black",
  },
  image: {
    maxWidth: "70px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60px",
    },
  },
  white: {
    color: "white",
  },
  marginAuto: {
    margin: "auto 0px",
  },
  d_flex: {
    display: "flex",
  },
  justifyCenter: {
    justifyContent: "center !Important",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  justifyEvenly: {
    justifyContent: "space-evenly",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  flex_wrap: {
    flexWrap: "wrap !Important",
  },
  ml5: {
    marginLeft: "5px",
  },
  ml10: {
    marginLeft: "10px",
  },
  ml20: {
    marginLeft: "20px",
  },
  ml40: {
    marginLeft: "40px",
  },
  width100: {
    width: "100%",
  },
  dropdown: {
    background: "transparent !Important",
    border: "none !Important",
    color: "white",
    outline: "none",
    fontFamily: "Inter !important",
    [theme.breakpoints.up("xs")]: {
      // color: "#008e7a",
      fontSize: "14px",
      fontWeight: "500",
    },
  },

  dropdown2: {
    left: "15px",
    width: "100%",
    background: "transparent",
    color: "black",
    border: "1px solid white",
    outline: "none",
  },
  button: {
    height: "40px",
    background: "#ffff",
    color: "#008E7A !Important",
    margin: "auto",
    borderRadius: "20px",
    border: "none",
    fontSize: "15px",
    fontFamily: "Mulish",
    fontWeight: "600",
    paddingLeft: "20px !Important",
    paddingRight: "20px !Important",
    marginLeft: "20px",
    whiteSpace: "nowrap",
    "@media screen and (max-width:1100px)": {
      height: "auto !important",
      padding: "7px 10px !important",
      fontSize: "13px !important",
    },
  },
  button2: {
    height: "40px",
    background: "rgba(0, 142, 122, 1)",
    color: "white",
    borderRadius: "20px",
    border: "none",
    fontSize: "15px",
    fontFamily: "Mulish",
    fontWeight: "600",
    paddingLeft: "20px !Important",
    paddingRight: "20px !Important",
    marginTop: "auto",
    marginBottom: "auto",
  },
  displayNone: {
    [theme.breakpoints.up("md")]: {
      // display: "none",
    },
  },
  displayBlock: {
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  p1: {
    fontSize: "17px",
    fontFamily: "Mulish !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  textWhite: {
    color: "#fff !Important",
  },
  bgWhite: {
    background: "#008E7A",
    color: "#FFF !important",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  profile: {
    background: "rgba(0, 142, 122, 1)",
    color: "white",
    margin: "auto",
    borderRadius: "5px",
    border: "none",
    fontSize: "28px",
    fontFamily: "Mulish",
    fontWeight: "700",
    width: "60px",
    height: "50px",
  },
  profileName: {
    margin: "auto",
    marginLeft: "10px",
    fontFamily: "Mulish",
    fontWeight: "700",
    fontSize: "18px",
    color: "rgba(0, 142, 122, 1)",
  },
  ptnav: {
    padding: " 2px 30px",
  },

  customDateTimePicker: {
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      display: "none",
      margin: "auto !Important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "white !Important",
      "font-size": "17px",
    },
    "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
      "flex-direction": "row-reverse",
      margin: "auto",
    },
    "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
      margin: "auto !important",
    },
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
      opacity: "0.7",
    },
  },
  paddR: {
    paddingRight: " 9px",
  },
  alignItem: {
    alignItems: "center",
  },
  calenderr: {
    color: "white",
    height: "27px",
    width: "50px",
    backgroundColor: "transparent",
    border: "0px",
    fontSize: "17px",
  },
  calendermob: {
    color: "Black",
    height: "27px",
    width: "180px",
    backgroundColor: "transparent",
    border: "0px",
    fontSize: "17px",
    "@media screen and (max-width:992px)": {
      color: "#fff",
      fontSize: "15px",
    },
    "@media screen and (max-width:767px)": {
      fontSize: "14px !important",
      color: "#fff !important",
      width: "100% !important",
    },
  },
  borderL: {
    borderLeft: "1px solid rgba(219, 215, 215, 1)",
    // borderRight: "1px solid rgba(219, 215, 215, 1)",
    paddingInline: " 8px",
    marginInline: " 8px",
    height: "23px",
    alignItems: "center",
  },
  dflex: {
    display: "flex",
    alignItems: "center",
  },
  inputspac: {
    border: "none !important",
    color: "white",
    height: "30px",
    width: " fit-content",
    backgroundColor: "transparent",
    fontSize: "17px",
    fontFamily: "Mulish !important",
    "@media screen and (max-width:1200px)": {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  inputspacmob: {
    color: "black",
    height: "30px",
    width: " 76px",
    backgroundColor: "transparent",
    border: "0px",
    fontSize: "17px",
    fontFamily: "Mulish !important",
    "@media screen and (max-width:992px)": {
      color: "#fff",
    },
  },
  svgh: {
    width: ".9em!important",
  },
  padl: {
    paddingLeft: "30px",
  },
  appBar: {
    background: "rgba(0, 142, 122, 1) !important",
    color: "white",
    boxShadow: "none !important",
    borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
    position: "relative !important",
    fontFamily: "Mulish !important",
    "max-height": "95px",
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  let role = localStorage.getItem("EM_User");
  role = JSON.parse(role);
  if (role?.data) {
    role = role?.data;
  }
  const { language } = useUserContext();
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.date.selectedDate);
  console.log(selectedDate, "======selected===date");

  const [content, setContent] = useState({});
  const [profileState, setProfileState] = useState(false);
  const [vendorprofileState, setVendorProfileState] = useState(false);
  const [locationModalState, setLocationModalState] = useState(false);
  const [locations, setLocation] = useState("");
  const [splitProfileName, setSplitProfileName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const datePickerRef = React.useRef(null);
  const wrapperRef = React.useRef(null); // Ref for the entire component

  const handleChange = (date) => {
    console.log(date);

    dispatch(setDate(date)); // Dispatch the selected date to Redux
  };
  useEffect(() => {
    if (!selectedDate) {
      // If selectedDate is not set, dispatch a default date
      dispatch(setDate(new Date())); // Sets the current date as default
    }
  }, [selectedDate, dispatch]); // Ensure dependencies are included

  const handleChangeNew = (date) => {
    console.log(date);
    dispatch(setDate(date)); // Dispatch the selected date to Redux
    setMobileOpen(!mobileOpen);
  };
  console.log(isMobileOpen, "isMobileOpen");

  const handleClickNew = () => {
    console.log("New change");
    setIsMobileOpen(!isMobileOpen);
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
    // setMobileOpen(!mobileOpen);
  };

  const [age, setAge] = React.useState("Langauge");

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const langHandleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (locations) {
      localStorage.setItem("EM_LOCATION", locations);
    } else {
      let loc = localStorage.getItem("EM_LOCATION");
      setLocation(loc || "");
    }
  }, [locations]);

  useEffect(() => {
    if (language === "english") {
      setContent(home.english);
    } else {
      setContent(home.arabic);
    }
  }, [language]);

  useEffect(() => {
    if (role?.role !== "user") {
      if (role?.name?.split(" ").length > 1)
        setSplitProfileName(
          role?.name?.split(" ")?.[0]?.slice(0, 1).toUpperCase() +
            role?.name?.split(" ")?.[1]?.slice(0, 1).toUpperCase()
        );
      else setSplitProfileName(role?.name?.slice(0, 1).toUpperCase());
    } else if (role?.role === "vendor") {
      if (role?.name?.split(" ").length > 1)
        setSplitProfileName(
          role?.firstname?.split(" ")?.[0]?.slice(0, 1).toUpperCase() +
            role?.lastname?.split(" ")?.[1]?.slice(0, 1).toUpperCase()
        );
      else setSplitProfileName(role?.firstname?.slice(0, 1).toUpperCase());
    }
  }, [role?.name]);

  useEffect(() => {
    let name = "";
    if (role?.role === "user") {
      name += role?.firstname[0].toUpperCase();
      if (role?.lastname) name += role?.lastname[0].toUpperCase();
      setSplitProfileName(name);
    }
  }, [role?.firstname]);

  useEffect(() => {
    let name = "";
    if (role?.role === "vendor") {
      name += role?.firstname[0].toUpperCase();
      if (role?.lastname) name += role?.lastname[0].toUpperCase();
      setSplitProfileName(name);
    }
  }, [role?.firstname]);

  const logout = () => {
    setProfileState(!profileState);
    localStorage.removeItem("EM_User");
    localStorage.removeItem("EM_TOKEN");
    localStorage.removeItem("EM_LOCATION");
    navigate("/");
  };

  const logoutVendor = () => {
    setVendorProfileState(!vendorprofileState);
    localStorage.removeItem("EM_User");
    localStorage.removeItem("EM_TOKEN");
    localStorage.removeItem("EM_LOCATION");
    navigate("/");
  };

  const profileStateHandler = () => {
    setProfileState(!profileState);
  };

  const vendorprofileStateHandler = () => {
    console.log("vendorprofileStateHandler called");
    setVendorProfileState((prevState) => !prevState);
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function handleLocationPopup() {
    setLocationModalState(!locationModalState);
    setMobileOpen(!mobileOpen);
  }

  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <>
      <LocationModal
        locationModalState={locationModalState}
        handleLocationPopup={handleLocationPopup}
        setLocation2={setLocation}
      />
      <AppBar component="nav" className={classes.appBar}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box
            className={`${classes.userCustomHeader} d-flex align-items-center justify-content-between`}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                color: "#fff !Important",
                display: { md: "none !Important" },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Link to={"/"} className="p-2">
              {" "}
              <img src={logo} alt="" className={`${classes.image} img-fluid`} />
            </Link>
          </Box>
          <Box
            className="flex-grow-1 d-flex align-items-center justify-content-end  column-gap-3"
            sx={{
              marginLeft: { lg: "24px", md: "20px", sm: "20px", xs: "0px" },
              marginRight: { lg: "24px", md: "0px", sm: "0px", xs: "0px" },
              "@media screen and (min-width:900px)": {
                justifyContent: "space-between !important",
              },
            }}
          >
            <Box
              className={` ${classes.d_flex} ${classes.alignItem}`}
              sx={{ display: { xs: "none !Important", md: "flex !Important" } }}
            >
              <Box className={` ${classes.dflex}`}>
                <Button
                  className={`${classes.hover} d-flex align-items-center gap-1 px-0 text-white`}
                  sx={{
                    color: "white",
                    minWidth: "fit-content",
                    fontFamily: "Mulish !important",
                    fontWeight: "600 !important",
                    fontSize: "16px !important",
                  }}
                  onClick={handleLocationPopup}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 10.4782C15 11.2721 14.6839 12.0336 14.1213 12.5951C13.5587 13.1565 12.7956 13.4719 12 13.4719C11.2044 13.4719 10.4413 13.1565 9.87868 12.5951C9.31607 12.0336 9 11.2721 9 10.4782C9 9.68415 9.31607 8.92267 9.87868 8.36123C10.4413 7.79979 11.2044 7.48438 12 7.48438C12.7956 7.48438 13.5587 7.79979 14.1213 8.36123C14.6839 8.92267 15 9.68415 15 10.4782Z"
                      stroke="white"
                      stroke-width="1.27756"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M19.5 10.4786C19.5 17.6058 12 21.7052 12 21.7052C12 21.7052 4.5 17.6058 4.5 10.4786C4.5 8.49358 5.29018 6.58989 6.6967 5.18628C8.10322 3.78268 10.0109 2.99414 12 2.99414C13.9891 2.99414 15.8968 3.78268 17.3033 5.18628C18.7098 6.58989 19.5 8.49358 19.5 10.4786Z"
                      stroke="white"
                      stroke-width="1.27756"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <Typography
                    variant="span"
                    style={{
                      fontFamily: "Mulish !important",
                      color: "#fff !important",
                      maxWidth: "100px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {locations}
                  </Typography>
                </Button>
                <Box
                  className={` ${classes.borderL} ${classes.dflex} align-items-center gap-1`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleClick}
                    className="cursor-pointer"
                  >
                    <path
                      d="M4.75 1V3.25M15.25 1V3.25M1 16.75V5.5C1 4.90326 1.23705 4.33097 1.65901 3.90901C2.08097 3.48705 2.65326 3.25 3.25 3.25H16.75C17.3467 3.25 17.919 3.48705 18.341 3.90901C18.7629 4.33097 19 4.90326 19 5.5V16.75M1 16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H16.75C17.3467 19 17.919 18.7629 18.341 18.341C18.7629 17.919 19 17.3467 19 16.75M1 16.75V9.25C1 8.65326 1.23705 8.08097 1.65901 7.65901C2.08097 7.23705 2.65326 7 3.25 7H16.75C17.3467 7 17.919 7.23705 18.341 7.65901C18.7629 8.08097 19 8.65326 19 9.25V16.75"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Box className="relative" ref={wrapperRef}>
                    <input
                      value={
                        selectedDate
                          ? format(
                              new Date(selectedDate),
                              "dd/MM/yyyy HH:mm aa"
                            )
                          : ""
                      }
                      readOnly
                      className={`${classes.inputspac} bg-transparent text-white cursor-pointer`}
                      onClick={handleClick}
                    />
                    {isOpen && (
                      <Box className="top-100 position-absolute">
                        <DatePicker
                          selected={
                            selectedDate ? new Date(selectedDate) : null
                          }
                          showTimeSelect
                          onChange={handleChange}
                          dateFormat="MM d, yyyy h:mm aa"
                          timeIntervals={15} // Time intervals can be customized (e.g., 15 minutes)
                          minDate={new Date()}
                          inline
                          ref={datePickerRef}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                {/* <Box className={`${classes.dflex} align-items-center gap-1`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 4V10H14.5M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <input
                    className={`${classes.customDateTimePicker} ${classes.inputspac}`}
                    value={moment(new Date()).format("HH:mm")}
                  />
                </Box> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none !Important", md: "flex !Important" },
                gap: { lg: "28px", md: "20px", sm: "16px", xs: "16px" },
                width: { md: "33.33%", xs: "auto" },
                justifyContent: "end",
                paddingRight: { md: "4%", xs: "0px" },
              }}
            >
              <Typography className={`${classes.p1} ${classes.hover}`}>
                <Link
                  to={"/?section=create"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {content?.create}{" "}
                </Link>
              </Typography>
              <Typography className={`${classes.p1} ${classes.hover}`}>
                <Link
                  to="/automate_event"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {content?.automate}
                </Link>
              </Typography>
              <Typography className={`${classes.p1} ${classes.hover}`}>
                <Link
                  to="/search-by-category?category=All"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {content?.vendors}
                </Link>
              </Typography>
            </Box>
            <Box
              className="d-flex align-items-center justify-content-end "
              sx={{
                gap: { lg: "28px", md: "10px", sm: "10px", xs: "10px" },
                width: { md: "33.33%", xs: "auto" },
              }}
            >
              {role?.role === "user" ? (
                " "
              ) : (
                <Typography
                  className={`${classes.p1} ${classes.hover} lh-1 d-none d-lg-flex`}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  <Link
                    to={"/signup-vendor-details"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {content?.becomeAvendor}
                  </Link>
                </Typography>
              )}
              <Box className="d-flex align-item-center justify-content-end gap-3">
                <Box
                  sx={{
                    display: { xs: "none !Important", md: "flex !Important" },
                    fontFamily: "Mulish",
                  }}
                >
                  <select
                    name=""
                    id=""
                    className={`${classes.dropdown}  ${classes.p1} ${classes.hover} ${classes.textWhite}`}
                    value={language}
                    onChange={(e) => userLanguage(e.target.value, dispatch)}
                  >
                    <option
                      value="english"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      English
                    </option>
                    <option
                      value="arabic"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      عربي
                    </option>
                  </select>
                </Box>
                <Box
                  className={`${classes.d_flex} `}
                  sx={{
                    display: { xs: "none !Important", sm: "flex !Important" },
                  }}
                >
                  {role?.role === "user" ? (
                    <Box className={classes.d_flex}>
                      {role?.profileImageUrl ? (
                        <Box className={`${classes.profile} ${classes.hover}`}>
                          <img
                            src={role?.profileImageUrl}
                            onClick={() => {
                              profileStateHandler();
                            }}
                            alt=""
                            className="object-fit-cover h-100 w-100 rounded-3"
                          />
                        </Box>
                      ) : (
                        <Typography
                          className={`${classes.profile} ${classes.hover}`}
                          onClick={() => {
                            profileStateHandler();
                          }}
                        >
                          {splitProfileName}
                        </Typography>
                      )}
                      {/* <Typography className={`${classes.profileName}`}>{splitProfileName}</Typography> */}
                    </Box>
                  ) : role?.role === "vendor" ? (
                    <Box className={classes.d_flex}>
                      {role?.profileImageUrl ? (
                        <Box className={`${classes.profile} ${classes.hover}`}>
                          <img
                            src={role?.profileImageUrl}
                            width="50"
                            className="object-fit-cover h-100 w-100 rounded-3"
                            onClick={vendorprofileStateHandler}
                            alt="profile"
                          />
                        </Box>
                      ) : (
                        <Typography
                          className={`${classes.profile} ${classes.hover}`}
                          onClick={vendorprofileStateHandler}
                        >
                          {splitProfileName}
                        </Typography>
                      )}
                      {/* <Typography className={`${classes.profileName}`}>{splitProfileName}</Typography> */}
                    </Box>
                  ) : (
                    <button className={`${classes.button} ms-0`}>
                      <Link
                        to={"/loginv2"}
                        style={{
                          textDecoration: "none",
                          color: "#008E7A",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {content?.loginNSignup}
                      </Link>
                    </button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            className={`${classes.d_flex} `}
            sx={{
              color: "#fff",
              display: { xs: "flex !Important", sm: "none !Important" },
            }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{ padding: "0px" }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block !Important", md: "none !Important" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              height: "100%",
              minHeight: "100vh",
              background: "#008e7a",
              padding: "0px",
              "@media screen and (max-width:676px)": {
                width: "280px",
                padding: "0px",
              },
            },
          }}
        >
          <Box
            onClick={handleDrawerToggle}
            className={`${classes.sideNav} d-flex flex-column h-100 align-content-between pb-3`}
          >
            <Box>
              <Box
                component={"div"}
                className="w-100 d-flex flex-column align-items-center border-bottom"
                sx={{
                  "border-bottom-color": "rgb(0 110 95) !important",
                }}
              >
                <Box
                  sx={{ borderBottom: " 1px solid rgb(0 110 95) !important" }}
                  className="w-100 p-2"
                >
                  <Button
                    className={`d-flex flex-row gap-1 align-items-center w-100 p-0`}
                    onClick={handleLocationPopup}
                    sx={{
                      color: "#fff !important",
                      fontFamily: "Mulish !important",
                      fontWeight: "600 !important",
                      fontSize: "14px !important",
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 10.4782C15 11.2721 14.6839 12.0336 14.1213 12.5951C13.5587 13.1565 12.7956 13.4719 12 13.4719C11.2044 13.4719 10.4413 13.1565 9.87868 12.5951C9.31607 12.0336 9 11.2721 9 10.4782C9 9.68415 9.31607 8.92267 9.87868 8.36123C10.4413 7.79979 11.2044 7.48438 12 7.48438C12.7956 7.48438 13.5587 7.79979 14.1213 8.36123C14.6839 8.92267 15 9.68415 15 10.4782Z"
                        stroke="white"
                        stroke-width="1.27756"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19.5 10.4786C19.5 17.6058 12 21.7052 12 21.7052C12 21.7052 4.5 17.6058 4.5 10.4786C4.5 8.49358 5.29018 6.58989 6.6967 5.18628C8.10322 3.78268 10.0109 2.99414 12 2.99414C13.9891 2.99414 15.8968 3.78268 17.3033 5.18628C18.7098 6.58989 19.5 8.49358 19.5 10.4786Z"
                        stroke="white"
                        stroke-width="1.27756"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <Typography
                      component={"p"}
                      style={{
                        width: "100%",
                        "text-overflow": "ellipsis",
                        "white-space": "nowrap",
                        overflow: "hidden",
                        fontFamily: "Mulish !important",
                        textAlign: "start",
                      }}
                    >
                      {/* {locations} */}
                      {locations || "Select the loction"}
                    </Typography>
                  </Button>
                </Box>

                <Box
                  className={`d-flex flex-row gap-2 align-items-center w-100 p-2`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.75 1V3.25M15.25 1V3.25M1 16.75V5.5C1 4.90326 1.23705 4.33097 1.65901 3.90901C2.08097 3.48705 2.65326 3.25 3.25 3.25H16.75C17.3467 3.25 17.919 3.48705 18.341 3.90901C18.7629 4.33097 19 4.90326 19 5.5V16.75M1 16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H16.75C17.3467 19 17.919 18.7629 18.341 18.341C18.7629 17.919 19 17.3467 19 16.75M1 16.75V9.25C1 8.65326 1.23705 8.08097 1.65901 7.65901C2.08097 7.23705 2.65326 7 3.25 7H16.75C17.3467 7 17.919 7.23705 18.341 7.65901C18.7629 8.08097 19 8.65326 19 9.25V16.75"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <input
                    readOnly
                    className={`${classes.customDateTimePicker} ${classes.calendermob} `}
                    value={
                      selectedDate
                        ? format(new Date(selectedDate), "dd/MM/yyyy HH:mm aa")
                        : ""
                    }
                    onClick={handleClickNew}
                  />
                </Box>
                {isMobileOpen && (
                  <Box
                    className=" top-50 start-50 translate-middle position-fixed mobileMenuDatePicker"
                    sx={{ zIndex: 999, width: "98%", maxWidth: "320px" }}
                  >
                    <DatePicker
                      selected={selectedDate ? new Date(selectedDate) : null}
                      showTimeSelect
                      onChange={handleChangeNew}
                      dateFormat="MM d, yyyy h:mm aa"
                      timeIntervals={15} // Time intervals can be customized (e.g., 15 minutes)
                      minDate={new Date()}
                      inline
                      ref={datePickerRef}
                    />
                  </Box>
                )}
              </Box>
              <List
                className={`${classes.mobileLinkList} py-0`}
                sx={{
                  borderBottom: "1px solid rgb(0 110 95) !important",
                  paddingBottom: "0",
                  marginBottom: "10px",
                }}
              >
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: "center" }}>
                    <Link
                      className={`${classes.textWhite} ${classes.p1} ${classes.hover}`}
                      to={"/?section=create"}
                    >
                      {content?.create}{" "}
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: "center" }}>
                    <Link
                      className={`${classes.textWhite} ${classes.p1} ${classes.hover}`}
                      to="/automate_event"
                      // style={{ textDecoration: "none", color: "white" }}
                    >
                      {content?.automate}
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Link
                      className={`${classes.textWhite} ${classes.p1} ${classes.hover}`}
                      to="/search-by-category?category=All"
                    >
                      {content?.vendors}
                    </Link>
                  </ListItemButton>
                </ListItem>
                {role?.role === "user" ? (
                  ""
                ) : (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <Link
                        className={`${classes.textWhite} ${classes.p1} ${classes.hover}`}
                        to={"/signup-vendor-details"}
                      >
                        {content?.becomeAvendor}
                      </Link>
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Box>
            <Box className="sideBarFooter mt-0">
              <Box className="LanguageBox px-2">
                <select
                  name=""
                  id=""
                  className={`form-select`}
                  value={language}
                  onChange={(e) => {
                    userLanguage(e.target.value, dispatch);
                    // Optionally, you can also toggle mobileOpen on change
                    setMobileOpen(!mobileOpen);
                  }}
                  onClick={() => setMobileOpen(!mobileOpen)} // Add onClick here
                >
                  <option value="english">English</option>
                  <option value="arabic">عربي</option>
                </select>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </nav>

      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        className="mobileLanguageProfile"
      >
        <MenuItem>
          <Box className={`${classes.d_flex} `}>
            {role?.role === "user" ? (
              <Box className={classes.d_flex}>
                {role?.profileImageUrl ? (
                  <img
                    src={role?.profileImageUrl}
                    width="50"
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={() => {
                      profileStateHandler();
                    }}
                    alt="profile"
                  />
                ) : (
                  <Typography
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={() => {
                      profileStateHandler();
                    }}
                  >
                    {splitProfileName}
                  </Typography>
                )}
                {/* <Typography className={`${classes.profileName}`}>{splitProfileName}</Typography> */}
              </Box>
            ) : role?.role === "vendor" ? (
              <Box className={classes.d_flex}>
                {role?.profileImageUrl ? (
                  <img
                    src={role?.profileImageUrl}
                    width="50"
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={vendorprofileStateHandler}
                    alt={""}
                  />
                ) : (
                  <Typography
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={vendorprofileStateHandler}
                  >
                    {splitProfileName}
                  </Typography>
                )}
                {/* <Typography className={`${classes.profileName}`}>{splitProfileName}</Typography> */}
              </Box>
            ) : (
              <Button
                className={`${classes.button} ms-0 py-0`}
                sx={{ paddingInline: "0 !important" }}
              >
                <Link
                  to={"/loginv2"}
                  style={{ textDecoration: "none", color: "#008E7A" }}
                >
                  {content?.loginNSignup}
                </Link>
              </Button>
            )}
          </Box>
        </MenuItem>
      </Menu>

      {/* <GoogleMap zoom={10} center={{lat : 44 , lng : -80}} style={{width : "100%" , height : "100vh"}}>
        <Marker
        position={{lat : 44 , lng : -80}}/>
      </GoogleMap> */}
      {profileState ? (
        <ProfilePopUp
          name={splitProfileName}
          user={role}
          logout={logout}
          profileStateHandler={profileStateHandler}
          profileState={profileState}
        />
      ) : (
        <></>
      )}

      {vendorprofileState ? (
        <VendorProfilePopUP
          name={splitProfileName}
          user={role}
          logout={logoutVendor}
          vendorprofileStateHandler={vendorprofileStateHandler}
          vendorprofileState={vendorprofileState}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Navbar;
