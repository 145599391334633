import * as yup from "yup";

export const validationSchema = yup
  .object({
    name: yup.string().required().max(50, "Max 50 characters allowed"),
    email: yup.string().email().required(),
    password: yup.string().required().min(8, "Your Password should have 8 "),
    rePassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

export const loginValidationSchema = yup.object({
  emailId: yup
    .string()
    .required("Email is required field")
    .email("Email must be in valid format"),
  password: yup.string().required("Password is required field"),
});

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]$/;

export const signupValidationSchema = yup
  .object({
    firstname: yup
      .string()
      .required("First Name is required field")
      .max(50, "Max 50 characters allowed")
      .matches(/^[a-zA-Z]+$/, "Only alphabet character allowed"),
    lastname: yup
      .string()
      .required("Last Name is required field")
      .max(50, "Max 50 characters allowed")
      .matches(/^[a-zA-Z]+$/, "Only alphabet character allowed"),
    emailId: yup
      .string()
      .required("Email is  required field")
      .email("Email must be in valid format"),
    mobile: yup
      .string()
      .required("Mobile Number is  required field")
      .matches(
        /^(\+\d{1,3})?\d{8,20}$/,
        "Invalid mobile number. Must be between 9 and 20 digits."
      ),
    password: yup
      .string()
      .required("Please enter your Password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*[!@#\$%\^&\*])/,
        "Password must contain at least one special character"
      ),
    repassword: yup
      .string()
      .required("Please confirm your Password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    terms: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
  })
  .required();

export const forgetValidationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required field"),
});

export const vendorCreateAccountValidation = yup
  .object({
    firstname: yup
      .string()
      .trim("First Name cannot start with a space")
      .required("First Name is required field")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed")
      .max(50, "Max 50 characters allowed"),
    lastname: yup
      .string()
      .trim("Last Name cannot start with a space")
      .required("Last Name is required field")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
    emailId: yup
      .string()
      .trim("Email cannot start with a space")
      .required("Email is required field")
      .email("Invalid email format"),
    mobile: yup
      .string()
      .trim("Mobile number cannot start with a space")
      .required("Mobile number is required field")
      .matches(
        /^(\+\d{1,3})?\d{8,20}$/,
        "Invalid mobile number. Must be between 9 and 20 digits."
      ),
    password: yup
      .string()
      .trim("Password cannot start with a space")
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*[!@#\$%\^&\*])/,
        "Password must contain at least one special character"
      ),
  })
  .required();

export const companyCreateAccountValidation = yup
  .object({
    company_name: yup
      .string()
      .trim("Company Name cannot start with a space")
      .required("Company Name is Required field")
      .max(50, "Max 50 characters allowed"),

    bussiness_type: yup
      .string()
      .trim("Bussiness Type cannot start with a space")
      .required("Bussiness Type is Required field"),

    tax_registration_number: yup
      .string()
      .trim("Tax Registration Number cannot start with a space")
      .required("Tax Registration Number is Required field"),

    bussiness_location: yup
      .string()
      .trim("Bussiness Location cannot start with a space")
      .required("Bussiness Location is Required field"),

    coverage_area: yup
      .string()
      .trim("Coverage Area cannot start with a space")
      .required("Coverage Area is Required field"),

    number_of_branches: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      ) // Convert empty string to undefined
      .nullable() // Allow null values
      .required("Number of Branches is required field")
      .positive("Number of Branches must be a positive number"),

    license_expiry_date: yup
      .date()
      .typeError("License Expiry Date is required field")
      .required("License Expiry Date is required field")
      .min(new Date(), "License Expiry Date must be in the future"),

    license_number: yup
      .string()
      .trim("License Number cannot start with a space")
      .required("License Number is required field"),

    single_owner: yup
      .string()
      .trim("Single Owner information cannot start with a space")
      .required("Required"),

    // working_hour: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       day: yup.string().required("Day is required"),
    //       openingTime: yup.string().required("Opening Time is required"),
    //       closingTime: yup.string().required("Closing Time is required"),
    //     })
    //   )
    //   .required("Working Hours are required"),
  })
  .required();

  const urlPattern = /^(?:(?:https?):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-./?%&=#]*)?$/i;

  // Custom URL validation
  // const urlValidation = yup.string()
  //   .test('is-url', 'Invalid URL', value => !value || urlPattern.test(value));
  

export const contactDetailsValidation = yup
  .object({
    company_email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required field"),

    website: yup
      .string()
      .required("Company Website is required field")
      .test(
        "is-url",
        "Invalid URL",
        (value) => !value || urlPattern.test(value)
      ),

    social_media_account: yup
      .string()
      .required("Social Media URL is required field")
      .test(
        "is-url",
        "Invalid URL",
        (value) => !value || urlPattern.test(value)
      ),

    contact_name: yup
      .string()
      .trim("Contact Name cannot start with a space")
      .required("Contact Name is required field")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),

    contact_number: yup
      .string()
      .required("Contact number is required field")
      .matches(
        /^(\+\d{1,3})?\d{8,15}$/,
        "Invalid contact number. Must be between 9 and 15 digits."
      ),

    contact_position: yup
      .string()
      .trim("Contact Position cannot start with a space")
      .required("Contact Position is required field")
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
  })
  .required();

export const bankDetailsValidation = yup
  .object({
    bank_account: yup
      .string()
      .trim("Bank account cannot start with a space")
      .matches(/^[0-9]*$/, "Bank account can only contain numbers")
      .required("Bank Account Number is required field"),

    beneficiary_name: yup
      .string()
      .trim("Beneficiary Name cannot start with a space")
      .required("Beneficiary Name is required field")
      .max(50, "Max 50 characters allowed"),

    bank_name: yup
      .string()
      .trim("Bank Name cannot start with a space")
      .required("Bank Name is required field"),

    iban_number: yup
      .string()
      .trim("IBAN number cannot start with a space")
      .required("IBAN number is required field")
      .max(100, "Max 100 characters allowed"),

    iban_certificate: yup
      .string()
      .trim("IBAN Certificate cannot start with a space")
      .required("IBAN Certificate is required field"),
  })
  .required();

export const itemDetailsValidation = yup
  .object({
    general_detail: yup
      .string()
      .required("General Details are required")
      .max(50, "Max 50 characters allowed"),
      selectedCategory: yup.array().min(1, "Please select a category").required("Please select a category"),
      selectedSubCategory: yup.array().min(1, "Please select a sub-category").required("Please select a sub-category"),
      isCheckboxChecked: yup
      .boolean()
      .oneOf([true], "You must agree to the terms and conditions"),
  })
  .required();

// =================================

// section_name_id:65bc95f7be30a43b1d27b8e6
// service_item_name:Bre
// item_description:Rum juice good for health
// event_type:654c5910a7a58b0f8ac2f66e
// service_item_price:90
// security_amount:0
// attendee_no[min]:10
// attendee_no[max]:2000
// service_item_location:Florida
// cancellation_policy:this is the policy for cancellation_policy
// deduction[number_of_days]:5
// deduction[deduction_percentage]:2
// down_payment_required:10
// setup_time:10
// maximum_hours:40
// area[length]:350
// area[width]:405
// electricity_outlet:true
// ventilation:false

const wordCount = (value) => {
  if (!value) return true; // If the value is empty or undefined, it will be handled by the required rule.
  return value.split(/\s+/).length <= 200; // Check if the number of words is less than or equal to 200
};

export const productValidation = yup.object({
  service_item_name: yup
    .string()
    .required("Item name is required")
    .max(50, "Max 50 characters allowed"),
  event_type: yup.string().required("Event type is required"),
  item_description: yup
    .string()
    .required("Item Description is required")
    .test("maxWords", "Item Description must be 200 words or less", wordCount),
  service_item_price: yup.string().optional(),

  discount: yup.object().shape({
    discount_percentage: yup
      .number()
      .typeError("Discount must be a number")
      .min(0, "Discount must be greater than or equal to 0")
      .max(100, "Discount must be less than or equal to 100")
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
    no_of_days: yup
      .number()
      .typeError("Number of days must be a number")
      .min(0, "Number of days must be greater than or equal to 0")
      .max(999, "Number of days must be less than or equal to 999")
      .test(
        "maxDigits",
        "Number of days must have at most 3 digits",
        (value) =>
          value === undefined || value === null || value.toString().length <= 3
      )
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable(),
  }),

  minimumBookingTime: yup
    .number()
    .typeError("Minimum booking time must be a number")
    .min(1, "Minimum booking time must be at least 1 hour")
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .nullable(),

  maximumBookingTime: yup
    .number()
    .typeError("Maximum booking time must be a number")
    .moreThan(
      yup.ref("minimumBookingTime"),
      "Maximum booking time must be greater than minimum booking time"
    )
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .nullable(),
  security_amount: yup
    .number().default(0)
    .optional()
   
    .test(
      "is-less-than-price",
      "Security Amount must be less than or equal to Item Price",
      function (value) {
        if(value){
          const itemPrice = this.parent.service_item_price; // Accessing the value of Item Price
          return parseFloat(value) <= parseFloat(itemPrice); // Comparing Security Amount with Item Price
        }
       return true
      }
    ),
  service_item_location: yup.string().optional(),
  attendee_no: yup.object().shape({
    min: yup
      .number()
      .required("Min number of attendees is required")
      .typeError("Min number of attendees must be a number")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .min(1, "Min number of attendees must be greater than or equal to 1"),
    max: yup
      .number()
      .required("Max number of attendees is required")
      .typeError("Max number of attendees must be a number")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .min(1, "Max number of attendees must be greater than or equal to 1")
      .min(
        yup.ref("min"),
        "Max attendees must be greater than or equal to min attendees"
      ),
  }),
  area: yup.object().shape({
    length: yup
      .number()
      .typeError("Length must be a number")
      .min(1, "Area length must be greater than or equal to 1")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value; // Convert empty string to null
      })
      .nullable(),
    width: yup
      .number()
      .typeError("Width must be a number")
      .min(1, "Area width must be greater than or equal to 1")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value; // Convert empty string to null
      })
      .nullable(),
  }),
  // setup_time: yup
  //   .number()
  //   .typeError("Setup time must be a number")
  //   .min(1, 'Setup time must be greater than or equal to 1')
  //   .transform((value, originalValue) => {
  //     return originalValue === '' ? null : value;
  //   })
  //   .nullable(),
  // maximum_hours: yup
  //   .number()
  //   .typeError("Maximum hours must be a number")
  //   .min(1, 'Maximum hours must be greater than or equal to 1')
  //   .transform((value, originalValue) => {
  //     return originalValue === '' ? null : value;
  //   })
  //   .nullable(),

  down_payment_required: yup
    .number()
    .typeError("Down payment must be a number")
    .min(0, "Down payment must be greater than or equal to 0")
    .max(100, "Down payment must be less than or equal to 100")
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value; // Convert empty string to null
    })
    .nullable(),
  deduction: yup.object().shape({
    deduction_percentage: yup
      .number()
      .min(0, "Discount must be greater than or equal to 0")
      .max(100, "Discount must be less than or equal to 100")
      .typeError("Deduction percentage must be a number")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value; // Convert empty string to null
      })
      .nullable(),
    number_of_days: yup
      .number()
      .min(0, "Number of days must be greater than or equal to 0")
      .max(999, "Number of days must be less than or equal to 999")
      .test(
        "maxDigits",
        "Number of days must have at most 3 digits",
        (value) => {
          return (
            value === undefined ||
            value === null ||
            value.toString().length <= 3
          );
        }
      )
      .typeError("Number of days must be a number")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value; // Convert empty string to null
      })
      .nullable()
      .min(0, "Number of days must be greater than 0"),
  }),
  dailyAvailableQuantity: yup
    .number()
    .required("Daily Available Quantity is required")
    .typeError("Daily Available Quantity must be a number")
    .positive("Daily Available Quantity must be a positive number")
    .integer("Daily Available Quantity must be an integer"),
});
// ===================================
